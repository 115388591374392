import { GetServerSideProps } from 'next';
import { PostDto, getBackendApi } from '../features/shared/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import Head from 'next/head';
import { useI18n } from '../features/i18n';
import { Main } from '../features/ui/main';
import { Feed } from '../features/blog/components';
import { usePosts } from '../features/blog/queries';

const limit = 50;

export interface Props {
  posts: PostDto[];
  query: {
    tags: string[];
    languages: PostDto['languages'];
  };
  limit: number;
  total: number;
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  context,
) => {
  const api = getBackendApi();
  const locale = context.locale;
  const languages = ((locale && [locale]) || []) as PostDto['languages'];
  const queryTags: string[] = [];

  if (typeof context.query['tags'] !== 'undefined') {
    const tags = Array.isArray(context.query['tags'])
      ? context.query['tags']
      : [context.query['tags']];
    queryTags.push(...tags);
  }

  const res = await api.blog.findPosts({
    offset: 0,
    limit,
    tags: queryTags,
    languages,
  });

  const { posts, total } = res.data;

  return {
    props: {
      posts,
      total,
      query: {
        languages,
        tags: queryTags,
      },
      limit,
    },
  };
};

export default function Home(props: Props) {
  const postsQuery = usePosts({
    languages: props.query.languages,
    tags: props.query.tags,
    limit: props.limit,
    initialData: [
      {
        total: props.total,
        posts: props.posts,
      },
    ],
  });

  const { i18n } = useI18n();
  const posts = postsQuery.data?.pages.flatMap((page) => page.posts) || [];

  let title = i18n('defaultPageTitle');

  if (props.query.tags.length > 0) {
    title = `#${props.query.tags.join(', #')} – ${title}`;
  }

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <Main>
        <InfiniteScroll
          next={postsQuery.fetchNextPage}
          hasMore={!!postsQuery.hasNextPage}
          loader={''}
          dataLength={posts.length || 0}
        >
          <Feed posts={posts} />
        </InfiniteScroll>
      </Main>
    </>
  );
}
